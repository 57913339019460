import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepBudgetVideoViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship-profiling-step-budget-video';

  video_title = '';

  video_src_webm = 'https://dv1k4uibauddz.cloudfront.net/presupuesto.webm';

  video_src_mp4 = 'https://dv1k4uibauddz.cloudfront.net/presupuesto.mp4';

  public constructor(view: Vue) {
    this.view = view;
    this.video_title = this.translate('title');
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
